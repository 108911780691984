<template>
  <div class="expert-think-tank">
    <div class="top-div">
      <div class="title-div">
        <span class="text-span">第一组</span>
      </div>
    </div>
    <div class="center-div">
      <div class="left-div">
        <img src="../assets/imgs/flfg0.png" width="100%" height="100%" alt="">
      </div>
      <div class="right-div">
        <p class="title-p">组长</p>
        <p class="content-p">1982年倪晋仁毕业于武汉水利电力学院治河工程系；1985年获得清华大学硕士学位；1989年获得清华大学博士学位；1991年进入北京大学城市与环境学系任教，先后担任副教授、教授、博士生导师；1994年获得国家自然科学基金委优秀中青年人才专项基金；1996年获得国家杰出青年基金资助；2002年组织建设北京大学环境工程实验室、成立环境工程系；2005年被评为全国优秀博士后 ；2015年增选为中国科学院院士 。</p>
      </div>
    </div>
    <div class="bottom-div">
      <div class="table-title">成员(8)</div>
      <div class="table-table">
        <el-table
        :data="tableData"
        style="width: 100%"
        height="250">
        <el-table-column
          prop="name"
          label="姓名"
          width="270">
        </el-table-column>
        <el-table-column
          prop="job"
          label="职务"
          width="270">
        </el-table-column>
        <el-table-column
          prop="beGoodAt"
          label="擅长领域"
          width="310">
        </el-table-column>
        <el-table-column
          prop="relevantCases"
          label="相关案例"
          width="310">
        </el-table-column>
      </el-table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExpertThinkTank',
  data(){
    return {
      tableData:[
        {
          name:'张三',
          job:'组员1',
          beGoodAt:'智能医疗',
          relevantCases:'Xxxxx',
        },
        {
          name:'李四',
          job:'组员2',
          beGoodAt:'智能识别',
          relevantCases:'Xxxxx',
        }
      ]
    }
  },
  props: {
    msg: String
  },
  created(){
  },
  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.expert-think-tank{
  width: 1375px;
  height: 810px;
  background: #000066;
  margin-top: 136px;
  @flex_column();
  .top-div{
    width: 100%;
    .title-div{
      width: 196px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin-left: 55px;
      margin-top: 44px;
      margin-bottom: 16px;
      background: #0F5A89;
      box-shadow: 0px 0px 2px 2px #14A2FF;
      transform: skew(30deg);
      .text-span{
        display: inline-block;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #FFFFFF;
        font-weight: 500;
        transform: skew(-30deg);
        @text_ellipsis_one();
      }
    }
  }
  .center-div{
    width: 100%;
    @flex_row();
    align-items: flex-start;
    .left-div{
      width: 240px;
      height: 320px;
      margin-left: 68px
    }
    .right-div{
      margin-left: 80px;
      width: 940px;
      .title-p{
        width: 100%;
        height: 40px;
        font-size: 32px;
        text-align: left;
        font-family: PingFangHK-Semibold, PingFangHK;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 12px;
        @text_ellipsis_one();
      }
      .content-p{
        width: 100%;
        max-height: 260px;
        text-align: justify;
        font-size: 24px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #FFFFFF;
        overflow: scroll;
        @thumb_scroll();
      }
    }

  }
  .bottom-div{
    width: calc(100% - 68px);
    margin-left: 68px;
    .table-title{
      width: 100%;
      text-align: left;
      margin-top: 50px;
      margin-bottom: 23px;
      font-size: 32px;
      font-family: PingFangHK-Semibold, PingFangHK;
      font-weight: 600;
      color: #FFFFFF;
    }
    .table-table{
      width: 1160px;
      /deep/ .el-table,/deep/.el-table__expanded-cell{
          background-color: transparent;
        }
      /deep/ .el-table {
        
        th.el-table__cell{
          background-color: rgba(57, 183, 250, 0.58);
        }
        tr{
          background-color:#0F5A89;
          &:hover>td{
            background-color:rgba(57, 183, 250, 0.58);
          }
        }
        td.el-table__cell{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.65);
        }
        th.el-table__cell.is-leaf{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.85);
          border-bottom: 1px solid #E8E8E8;
        }

      }
      
    }
  }
}
</style>
