import { render, staticRenderFns } from "./ExpertThinkTank.vue?vue&type=template&id=406c6436&scoped=true&"
import script from "./ExpertThinkTank.vue?vue&type=script&lang=js&"
export * from "./ExpertThinkTank.vue?vue&type=script&lang=js&"
import style0 from "./ExpertThinkTank.vue?vue&type=style&index=0&id=406c6436&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "406c6436",
  null
  
)

export default component.exports